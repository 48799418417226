import React, { useEffect, useRef, useState } from "react";
import CkEditorBlog from "../../LearningPath/CkEditorBlog";
export default function Chat({ socket, user, room, chatOpen }) {
    const [chatData, chatDataState] = useState([]);
    const [messagesReceived, setMessagesReceived] = useState([]);
    const [message, setMessage] = useState('');
    const messagesColumnRef = useRef(null);
    useEffect(() => {
        socket.on('receive_message', (data) => {
            setMessagesReceived((state) => [
                ...state,
                {
                    message: data.message,
                    scheduleId: room,
                    userId: user.id,
                    __createdtime__: data.createdAt,
                },
            ]);
        });
        return () => socket.off('receive_message');
    }, [socket]);
    useEffect(() => {
        socket.on('get_messages', (message) => {
            setMessagesReceived([]);
            message.forEach(element => {
                setMessagesReceived((state) => [
                    ...state, {
                        message: element.text,
                        scheduleId: element.scheduleId,
                        userId: element.userId,
                        __createdtime__: element.createdAt
                    }]);
            });
        });
        return () => socket.off('get_messages');
    }, [socket]);
    const sendMessage = (message) => {
        console.log('send')
        console.log(message)
        if (message !== '') {
            const __createdtime__ = Date.now();
            socket.emit('send_message', {
                message: message,
                userId: user.id,
                scheduleId: room,
                room: room,
                createdAt: __createdtime__
            });
            setMessage('');
        }
    };
    useEffect(() => {
        if (messagesColumnRef.current) {
            messagesColumnRef.current.scrollTop = messagesColumnRef.current.scrollHeight;
        }
    }, [messagesReceived]);

    function formatDateFromTimestamp(timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleString();
    }
    return (
        <>
            <div className={`col border bg-white m-4 rounded-3 shadow chat-box ${chatOpen ? 'd-block' : 'd-none'}`} style={{ height: '76%', width: '275px', bottom: '60px', zIndex: '-1' }}>
                <div className="border round-top-start" style={{ backgroundColor: '#F6F6F6' }}>
                    <div className="d-flex gap-3 mx-3 py-2">
                        <button type='button' className='border rounded-circle py-2 px-3 fw-bold'>{user.displayName.charAt(0)}</button>
                        <span className="my-auto fw-bold">{user.username}</span>
                    </div>
                </div>
                <div className="d-flex flex-column h-100 bg-white">
                    <div className="flex-fill overflow-auto" style={{ maxHeight: '87%' }} ref={messagesColumnRef}>
                        <ul className="fw-bold px-4 my-4 chat-container gap-2 " >
                            {
                                messagesReceived.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <li className={`d-flex gap-3 w-100`}>
                                                {
                                                    item.userId !== user.id && <button type='button' className='border rounded-circle mb-auto py-2 px-3 fw-bold'>{user.displayName.charAt(0)}</button>
                                                }
                                                {
                                                    item.username === 'ChatBot' ?
                                                        <div className="my-2">
                                                            <span dangerouslySetInnerHTML={{ __html: item.message }} />
                                                        </div> :
                                                        <div className={` ${item.userId === user.id ? 'flex-row-reverse' : ''}`}>
                                                            <p className="m-0 fw-bold">{user.displayName}</p>
                                                            <span dangerouslySetInnerHTML={{ __html: item.message }} />
                                                        </div>
                                                }
                                                {
                                                    item.userId === user.id && <button type='button' className='border rounded-circle mb-auto py-2 px-3 fw-bold'>{user.displayName.charAt(0)}</button>
                                                }
                                            </li>
                                            <li className="small mb-3 fw-normal">{formatDateFromTimestamp(item.__createdtime__)}</li>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="w-100 border">
                        <CkEditorBlog data={message} config={{
                            toolbar: ['bold', 'italic', 'link']
                        }} setData={setMessage} onSubmit={sendMessage} />
                    </div>
                </div>
            </div>
        </>
    )

}