export const MenuList = [
    //Dashboard
    {
        title:'Dashboard',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">dashboard</i>,
        to: 'admin/dashboard',
    },
    //Learning Path
    {
        title:'Learning Path',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">checklist</i>,
        to: 'admin/learning-path',
    },
    //Practicum
    {
        title:'Practicum',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">biotech</i>,
        to: 'admin/practicum',
    },
    //Test
    {
        title:'Test',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">edit</i>,
        to: 'admin/student-test',
    },
    //Schedule
    {
        title:'Schedule',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">calendar_today</i>,
        to: 'admin/schedule',
    },
    //Monitoring
    {
        title:'Monitoring',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">desktop_windows</i>,
        to: 'admin/monitoring',
    },
    //User Group
    {
        title:'User Group',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">group</i>,
        to: 'admin/user-group',
    },
    //User Management
    {
        title:'User Management',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">person</i>,
        to: 'admin/user-management',
    },
    //Web Management
    {
        title: 'Web Management',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="material-icons">language</i>,
        content: [
            {
                title: 'Hero',
                to: 'admin/web-management/hero',					
            },
            {
                title: 'Fancybox',
                to: 'admin/web-management/fancybox',					
            },
            {
                title: 'Feature',
                to: 'admin/web-management/feature',					
            },
            {
                title: 'Project',
                to: 'admin/web-management/project',					
            },
            {
                title: 'Documentation',
                to: 'admin/web-management/documentation',
            },
        ],
    }
]