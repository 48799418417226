import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import config from "../../../../config";
import { socket } from "../../../../services/SocketService";
import { useNavigate } from "react-router-dom";
const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [item,setItem] = useState({});
  const user = JSON.parse(localStorage.getItem('userDetails'));
  const img = config.apiUrl + 'uploads?image=practicums/'
  useImperativeHandle(ref, () => ({
    openModal(item) {
      setData(item)
      setItem(item.practicum ?? item.test)
      setModalBox(true);
    },
  }));
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const submit = async (e) => {
    socket.emit('join_room', { username: user.username, room: data.id });
    navigate(`/student/${data.test ? 'student-test' : 'practicum'}/start`, {
      state: { moduleId: data.test ? data.test.id : data.practicum.id, scheduleId: data.id, minutes: data.time },
      replace: true
    });
  };
  return (
    <>
      <Modal onHide={setModalBox} size="xl" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{item.module_name}</h5>
            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="video-img mb-3">
                <div className="view-demo">
                  <img src={img + item.image} />
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: item.des }} />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setModalBox(false)}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => submit(e)}>
              Start
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default BasicModal;
