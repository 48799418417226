import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import BasicModal from "./BasicModal";
import EditModal from "./EditModal";

import { useDispatch, useSelector } from "react-redux";
import { deletePostAction, getPostsAction, resetPosts } from "../../../store/actions/PostActions";
import config from "../../../config";

export const StudentTest = () => {
  const [searchBut, setSearchBut] = useState(false);
  const ModalEdit = useRef();
  const childRef = useRef();
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts);
  const [editData, setEditData] = useState(null);
  const [pageData, setPageData] = useState([]);
  const img = config.apiUrl + "uploads?image=tests/";

  const fetchData = async () => {
    try {
      await dispatch(
        getPostsAction("/test/index", {
          params: {
            pageSize: 8,
            pageNumber: 1,
          },
        })
      );
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };
  useEffect(() => {
    setPageData(posts.posts);
  }, [posts.posts]);
  useEffect(() => {
    setPageData([]);
    fetchData();
  }, []);
  const destroy = (e, id) => {
    e.preventDefault();
    dispatch(deletePostAction("/test/delete/", id));
  };
  const openEditModal = (item) => {
    setEditData(item);
    ModalEdit.current.openModal();
  };
  const handleEditSuccess = () => {
    fetchData();
  };
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Student Test</h4>
          <div className="header-left">
            <div className="input-group search-area">
              <input type="text" className={`form-control ${searchBut ? "active" : ""}`} placeholder="Search here..." />
              <span className="input-group-text">
                <Link to={"#"}>
                  <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                      fill="#01A3FF"
                    />
                  </svg>
                </Link>
              </span>
            </div>
          </div>
          <button
            onClick={() => childRef.current.openModal()}
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            New Test
            <svg
              width="24"
              height="24"
              className="ms-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z"
                fill="#FCFCFC"
              />
              <path
                d="M16.3498 11.0251H12.9748V7.65009C12.9748 7.12509 12.5248 6.67509 11.9998 6.67509C11.4748 6.67509 11.0248 7.12509 11.0248 7.65009V11.0251H7.6498C7.1248 11.0251 6.6748 11.4751 6.6748 12.0001C6.6748 12.5251 7.1248 12.9751 7.6498 12.9751H11.0248V16.3501C11.0248 16.8751 11.4748 17.3251 11.9998 17.3251C12.5248 17.3251 12.9748 16.8751 12.9748 16.3501V12.9751H16.3498C16.8748 12.9751 17.3248 12.5251 17.3248 12.0001C17.3248 11.4751 16.8748 11.0251 16.3498 11.0251Z"
                fill="#FCFCFC"
              />
            </svg>
          </button>
        </div>
        <div className="card-body">
          <div className="row">
            {pageData.map((item, index) => (
              <div className="col-xl-3 col-xxl-4 col-sm-6" key={index}>
                <div className="card all-crs-wid shadow">
                  <div className="card-body">
                    <div className="courses-bx">
                      <div className="dlab-media overlay-main position-relative">
                        <img src={img + item.image} alt="" />
                      </div>
                      <div className="h-100">
                        <div className="dlab-info">
                          <div className="dlab-title d-flex justify-content-between">
                            <div>
                              <h4>
                                <Link to={`/admin/student-test/${item.id}`}>{item.module_name}</Link>
                              </h4>
                            </div>
                          </div>
                          <div dangerouslySetInnerHTML={{ __html: item.des }} />
                          <div className="d-flex justify-content-between content align-items-center">
                            <Link to={`/admin/student-test/${item.id}`} className="btn btn-primary btn-sm">
                              View
                            </Link>
                            <span className="d-flex justify-content-end">
                              <Link
                                onClick={() => openEditModal(item)}
                                className="me-2 btn btn-primary shadow btn sharp"
                                title="Edit"
                              >
                                <i className="fas fa-pencil-alt color-muted"></i>
                              </Link>
                              <button
                                onClick={(e) => destroy(e, item.id)}
                                title="delete"
                                className="btn btn-danger shadow btn sharp"
                              >
                                <i className="fa fa-close color-danger"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="table-pagenation">
            <p className="ms-0">
              Showing <span>1-3</span>from <span>20</span>data
            </p>
            <nav>
              <ul className="pagination pagination-gutter pagination-primary no-bg">
                <li className="page-item page-indicator">
                  <Link to={"#"} className="page-link">
                    <i className="fa-solid fa-angle-left"></i>
                  </Link>
                </li>
                <li className="page-item active">
                  <Link to={"#"} className="page-link">
                    1
                  </Link>
                </li>
                <li className="page-item ">
                  <Link className="page-link">2</Link>
                </li>
                <li className="page-item">
                  <Link className="page-link">3</Link>
                </li>
                <li className="page-item page-indicator me-0">
                  <Link to={"#"} className="page-link">
                    <i className="fa-solid fa-angle-right"></i>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {/* Modal Create New Learning Path */}
      <BasicModal ref={childRef} />
      {/* Modal Edit Hero */}
      <EditModal ref={ModalEdit} editData={editData} onEditSuccess={handleEditSuccess} />
    </>
  );
};
export default StudentTest;
