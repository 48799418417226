import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import Avatar from "./Avatar";
import { updatePostAction, getPostsAction } from "../../../store/actions/PostActions";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
const EditModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
  }));
  const dispatch = useDispatch();
  const [students, setStudents] = useState([]);
  const [initialStudents, setInitialStudents] = useState([]);
  const [name, setName] = useState("");

  useEffect(() => {
    if (props.editData) {
      setName(props.editData.group_name);
      const formattedStudents = props.editData.group_students.map((student) => ({
        label: `${student.user.first_name} (${student.user.username})`,
        value: student.user.id,
      }));
      setStudents(formattedStudents);
      setInitialStudents(formattedStudents); // Simpan data siswa awal di state
    }
  }, [props.editData]);

  const searchData = async (inputValue, refer) => {
    const response = await dispatch(
      getPostsAction(`/user/index`, {
        params: {
          username: inputValue,
          pageSize: 8,
          pageNumber: 1,
          role: "student",
        },
      })
    );
    if (response) {
      return response?.map((item) => {
        return {
          label: `${item.first_name} ${item.last_name} (${item.username})`,
          value: item.id,
        };
      });
    }
    return [];
  };
  const submit = async (e) => {
    e.preventDefault();

    const selectedStudentIds = students.map((item) => item.value);
    const initialStudentIds = initialStudents.map((item) => item.value);
    const added = students.filter((student) => !initialStudentIds.includes(student.value));
    const deleted = initialStudents.filter((student) => !selectedStudentIds.includes(student.value));

    const data = {
      group_name: name,
      added: added.map((student) => ({ id: student.value })),
      deleted: deleted.map((student) => ({ id: student.value })),
    };
    if (props.editData && props.editData.id) {
      dispatch(updatePostAction(`/group/update`, data, props.editData.id))
        .then(() => {
          setModalBox(false);
          props.fetchData();
        })
        .catch((error) => {
          console.error("Error updating post:", error);
        });
    }
  };
  let timeoutId;
  const loadStudent = (inputValue, callback) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      if (inputValue.trim()) {
        searchData(inputValue).then((options) => {
          callback(options);
        });
      }
    }, 1000);
  };
  const handleChange = (selected) => {
    setStudents(selected);
    console.log(selected);
  };
  return (
    <>
      <Modal onHide={setModalBox} size="" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create New Group</h5>
            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
          </div>
          <div className="modal-body">
            <div className="mb-3 d-block">
              <label htmlFor="basic-url" className="form-label d-block">
                Group Name
              </label>
              <input
                type="text"
                className="form-control w-100"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-3 d-block">
              <label htmlFor="basic-url" className="form-label d-block">
                Student
              </label>
              <AsyncSelect
                isMulti
                name="Students"
                loadOptions={loadStudent}
                className="basic-multi-select"
                classNamePrefix="select"
                value={students}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setModalBox(false)}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => submit(e)}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default EditModal;
