import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { socket } from "../../../../services/SocketService";
import axios from "axios";
import config from "../../../../config";
import Meet from "../student/Meet";

export const PracticumVirtual = () => {
    const { id } = useParams();
    const apiUrl = config.apiUrl + "api";
    const [pageData, setPageData] = useState({});
    const fetchData = async () => {
        try {
            await axios
                .get(apiUrl + "/schedule/get-id/" + id)
                .then((res) => {
                    setPageData(res.data)
                })
                .catch((e) => {
                    console.error("Error fetching posts:", e);
                });
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        if (Object.keys(pageData).length > 0) {
            socket.emit('join_room', { username: 'camera', room: pageData.id });
        }
    }, [pageData]);
    return (
        <>
            <Meet socket={socket} camera={true} user={{ username: 'camera' }} />
        </>
    );
};
export default PracticumVirtual;
