export const MenuList = [
    //Dashboard
    {
        title:'Dashboard',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">dashboard</i>,
        to: 'student/dashboard',
    },
    //Practicum
    {
        title:'Practicum',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">biotech</i>,
        to: 'student/practicum',
    },
]