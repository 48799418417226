import React, { useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Activities from "../Activities/Activities";

export const StudentTestDetail = () => {
  const { id } = useParams();
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Test Detail</h4>

        </div>
        <div className="card-body">
          <div className="row">
            <Activities id={id} refer={"test"} />
          </div>
        </div>
      </div>
    </>
  );
};
export default StudentTestDetail;
