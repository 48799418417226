import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import CkEditorBlog from "../../LearningPath/CkEditorBlog";
import { createPostFileAction, updatePostFileAction } from "../../../../store/actions/PostActions";
import { useDispatch } from "react-redux";

const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const [editId, setEditId] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState('');
  const [des, setDes] = useState('');
  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
    openEdit(item) {
      setName(item.module_name);
      setDes(item.des)
      setEditId(item.id)
      setModalBox(true);
    }
  }));
  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const submit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (selectedFile) {
      formData.append('image', selectedFile);
    }
    formData.append('module_name', name);
    formData.append('des', des);
    let response;
    if (editId) {
      response = await dispatch(updatePostFileAction('/practicum/update', formData, editId))
    } else {
      response = await dispatch(createPostFileAction('/practicum/store', formData))
    }
    if (response) {
      setModalBox(false)
      setEditId('')
      props.fetchData();
    }
  };
  return (
    <>
      <Modal onHide={setModalBox} size="xl" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.title}</h5>
            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <div className="form-group mb-3">
                  <label htmlFor="name" className="form-label d-block">
                    Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    className="form-control w-100"
                    placeholder="Masukkan Nama"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      display: "block",
                      textAlign: "center",
                      lineHeight: "250px",
                      border: "1px dashed #ccc",
                      borderRadius: "4px",
                    }}
                  >
                    Upload Gambar (450x250)
                  </label>
                  <input id="file-upload" type="file" style={{ display: "none" }} onChange={handleFileChange} />
                  {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-3">
                  <label className="form-label d-block">About</label>
                  <CkEditorBlog data={des} setData={setDes} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setModalBox(false)}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => submit(e)}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default BasicModal;
