import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import CkEditorBlog from "./CkEditorBlog";
import { useDispatch } from "react-redux";
import { createPostFileAction } from "../../../store/actions/PostActions";

const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
  }));
  const [index, setIndex] = useState('');
  const [des, setDes] = useState('<p></p>');
  const [title, setTitle] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  
  const submit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('icon', selectedFile);
    formData.append('name', title);
    formData.append('index', index);
    formData.append('des', des);
    formData.append('short_name', abbreviation);

    const response = dispatch(createPostFileAction('/learning-path/store', formData))
    console.log(response)
    setModalBox(false)
  };
  return (
    <>
      <Modal onHide={setModalBox} size="xl" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create New Learning Path</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setModalBox(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <div className="mb-3 d-block">
                  <label htmlFor="basic-url" className="form-label d-block">
                    Index
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Index"
                    onChange={(e) => { setIndex(e.target.value) }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      display: "block",
                      textAlign: "center",
                      lineHeight: "250px",
                      border: "1px dashed #ccc",
                      borderRadius: "4px",
                    }}
                  >
                    Upload Gambar (450x250)
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-3">
                  <label htmlFor="basic-url" className="form-label d-block">
                    Deskripsi
                  </label>
                  <CkEditorBlog data={des} setData={setDes} />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Title"
                    onChange={(e) => { setTitle(e.target.value) }}
                    defaultValue={""}
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Abbreviation"
                    onChange={(e) => { setAbbreviation(e.target.value) }}
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={(e) => setModalBox(false)}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => submit(e)}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default BasicModal;
