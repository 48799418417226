import React, { useReducer } from "react";
import { Nav, Tab, ProgressBar, Modal, Table, Badge } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";


import "react-modal-video/scss/modal-video.scss";

import course7 from "./../../../../images/course/7.jpg";
import convertToSlug from "../../../../ConvertToSlug";

const practicum = {
  id: "1",
  image: course7,
  tags: ["Physics", "Bernoulli"],
  method: "Virtual",
  title: "Drone",
};

const learningMaterials = [
  {
    id: 1,
    name: "Lab Orientation Project",
    materials: [
      {
        id: 1,
        title: "Launching the Project",
        status: 1,
      },
      {
        id: 2,
        title: "Project Assignment",
        status: 1,
      },
      {
        id: 3,
        title: "Workspace",
        status: 1,
      },
      {
        id: 4,
        title: "Case Study",
        status: 0,
      },
      {
        id: 5,
        title: "Formulate Problems",
        status: 0,
      },
      {
        id: 6,
        title: "Hypothesis Submission",
        status: 0,
      },
    ],
  },
  {
    id: 2,
    name: "Lab Concept Reinforcement",
    materials: [
      {
        id: 7,
        title: "Launching the Project",
        status: 0,
      },
      {
        id: 8,
        title: "Project Assignment",
        status: 0,
      },
      {
        id: 9,
        title: "Workspace",
        status: 0,
      },
      {
        id: 10,
        title: "Case Study",
        status: 0,
      },
      {
        id: 11,
        title: "Formulate Problems",
        status: 0,
      },
      {
        id: 12,
        title: "Hypothesis Submission",
        status: 0,
      },
    ],
  },
  {
    id: 3,
    name: "Real World Project Lab",
    materials: [
      {
        id: 13,
        title: "Launching the Project",
        status: 0,
      },
      {
        id: 14,
        title: "Project Assignment",
        status: 0,
      },
    ],
  },
  {
    id: 4,
    name: "Project Testing Lab",
    materials: [
      {
        id: 15,
        title: "Launching the Project",
        status: 0,
      },
    ],
  },
  {
    id: 5,
    name: "Dessimination and Report Project",
    materials: [
      {
        id: 16,
        title: "Presentation",
        status: 0,
      },
      {
        id: 17,
        title: "Project Assignment",
        status: 0,
      },
      {
        id: 18,
        title: "Workspace",
        status: 0,
      },
    ],
  },
];

const activities = [
  {
    id: 1,
    activity: (
      <>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate quos aspernatur soluta tenetur hic
          quisquam autem odio commodi? Porro magni numquam sunt incidunt aut eligendi excepturi rerum. Animi id
          inventore quaerat? Quod distinctio laborum blanditiis reprehenderit cum itaque, labore ex atque nam tenetur
          repudiandae, tempore odit recusandae, quas est quae iste eum sequi in at repellat placeat minus magnam
          maiores? Porro cum, rerum nostrum, repudiandae ex suscipit quis totam, fugit iste dolorem id facere soluta
          expedita voluptate repellat accusamus ullam. Ipsum enim aut quibusdam atque provident facilis. Illo at animi
          ipsa est natus, veniam accusantium culpa quas id rem quaerat.
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate quos aspernatur soluta tenetur hic
          quisquam autem odio commodi? Porro magni numquam sunt incidunt aut eligendi excepturi rerum. Animi id
          inventore quaerat? Quod distinctio laborum blanditiis reprehenderit cum itaque, labore ex atque nam tenetur
          repudiandae, tempore odit recusandae, quas est quae iste eum sequi in at repellat placeat minus magnam
          maiores? Porro cum, rerum nostrum, repudiandae ex suscipit quis totam, fugit iste dolorem id facere soluta
          expedita voluptate repellat accusamus ullam. Ipsum enim aut quibusdam atque provident facilis. Illo at animi
          ipsa est natus, veniam accusantium culpa quas id rem quaerat.
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate quos aspernatur soluta tenetur hic
          quisquam autem odio commodi? Porro magni numquam sunt incidunt aut eligendi excepturi rerum. Animi id
          inventore quaerat? Quod distinctio laborum blanditiis reprehenderit cum itaque, labore ex atque nam tenetur
          repudiandae, tempore odit recusandae, quas est quae iste eum sequi in at repellat placeat minus magnam
          maiores? Porro cum, rerum nostrum, repudiandae ex suscipit quis totam, fugit iste dolorem id facere soluta
          expedita voluptate repellat accusamus ullam. Ipsum enim aut quibusdam atque provident facilis. Illo at animi
          ipsa est natus, veniam accusantium culpa quas id rem quaerat.
        </p>
      </>
    ),
    type: "Description",
  },
  {
    id: 2,
    activity: <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, fugiat?</p>,
    type: "Question",
  },
];

const progress = {
  finished: 3,
  total: 18,
  current: "Lab Orientation Project - Workspace",
};

const init = false;
const reducer = (state, action) => {
  switch (action.type) {
    case "activitiesModal":
      return { ...state, activitiesModal: !state.activitiesModal };
    default:
      return state;
  }
};

function CourseDetails() {
  const [state, dispatch] = useReducer(reducer, init);

  return (
    <>
      <div className="row">
        <div className="col-xl-9 col-xxl-8">
          <div className="card">
            <div className="card-body">
              <div className="course-content d-flex justify-content-between flex-wrap">
                <div>
                  <div className="d-flex align-items-center gap-3">
                    <h3 className="mb-0">{practicum.title}</h3>
                    <span className="badge badge-lg badge-warning">{practicum.method}</span>
                  </div>
                  <ul className="d-flex align-items-center raiting my-0 flex-wrap">
                    {practicum.tags.map((item, key) => {
                      return <li key={key}>{item}</li>;
                    })}
                  </ul>
                </div>
                <div>
                  <Link
                    to={`/student/practicum/start/${convertToSlug(practicum.title)}`}
                    className="btn btn-primary shadow"
                  >
                    Start
                  </Link>
                </div>
              </div>
              <div className="video-img style-1">
                <div className="view-demo">
                  <img src={course7} alt="" />
                </div>
              </div>
              <div className="course-details-tab style-2 mt-4">
                <Tab.Container defaultActiveKey={"About"}>
                  <Nav className="nav nav-tabs tab-auto" id="nav-tab" role="tablist">
                    <Nav.Link className="nav-link" eventKey="About">
                      About
                    </Nav.Link>
                    <span className="nav-link">Learning Path:</span>
                    {learningMaterials.map((item, key) => (
                      <Nav.Link className="nav-link" key={key} eventKey={"Path" + item.id}>
                        {item.id}
                      </Nav.Link>
                    ))}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="About">
                      <div className="about-content">
                        <h4>About This Course</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                          eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua.{" "}
                        </p>
                      </div>
                    </Tab.Pane>
                    {learningMaterials.map((item, key) => (
                      <Tab.Pane key={key} eventKey={"Path" + item.id}>
                        <div className="about-content">
                          <h3 className="heading mb-3">{item.name}</h3>
                          <PerfectScrollbar
                            style={{ height: "370px" }}
                            id="DZ_W_TimeLine"
                            className="widget-timeline dlab-scroll height370 ps ps--active-y"
                          >
                            <ul className="timeline">
                              {item.materials.map(({ id, title, status }) => {
                                const badge = status === 1 ? "timeline-badge primary" : "timeline-badge dark";
                                return (
                                  <li key={id}>
                                    <div className={badge}></div>
                                    <Link
                                      className="timeline-panel text-muted"
                                      data-toggle="modal"
                                      onClick={() => (status === 1 ? dispatch({ type: "activitiesModal" }) : "")}
                                      data-target="#activitiesModal"
                                    >
                                      <h6 className="mb-0">{title}</h6>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </PerfectScrollbar>
                        </div>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4">
          <div className="card h-auto">
            <div className="card-header border-0 pb-0">
              <h4>Progress</h4>
            </div>
            <div className="card-body pt-0">
              <div className="progress-box">
                <ProgressBar now={(progress.finished / progress.total) * 100} />
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0 fs-14 font-w600">{progress.current}</h5>
                  <span className="font-w600">
                    {progress.finished}/{progress.total}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Activities */}
      <Modal
        show={state.activitiesModal}
        onHide={() => dispatch({ type: "activitiesModal" })}
        className="fade bd-example-modal-lg"
        id="activitiesModal"
        size="xl"
      >
        <>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Activity</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => dispatch({ type: "activitiesModal" })}
              ></button>
            </div>
            <div className="modal-body">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Activity</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {activities.map(({ id, activity, type }, key) => (
                    <tr key={key}>
                      <th>{key + 1}</th>
                      <td className="text-wrap">{activity}</td>
                      <td>
                        <Badge bg="primary">{type}</Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default CourseDetails;
