import React, { useState, useReducer, useEffect, useRef } from "react";
import { Badge, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import BasicModal from "./BasicModal";
import { useDispatch } from "react-redux";
import { deletePostAction, getPostsAction } from "../../../../store/actions/PostActions";

export const SubLearningPath = ({ id, refer }) => {
  const dispatch = useDispatch();
  const [activities, setActivities] = useState([]);
  const methodList = ['', 'Virtual', 'Hybrid', 'On-site'];
  const childRef = useRef();
  useEffect(() => {
    fetchActivities(id)
  }, [id])
  const destroyActivity = async (e, deleteId) => {
    e.preventDefault();
    const response = await dispatch(deletePostAction('/sub-module/delete/', deleteId))
    if (response) {
      fetchActivities(id)
    }
  }
  const fetchActivities = async (subId) => {
    try {
      const posts = await dispatch(getPostsAction('/' + refer + '/index', {
        params: {
          learningPathId: subId,
        }
      }))
      if (posts) {
        setActivities(posts)
      }
    } catch (error) {

    }
  }
  return (
    <>
      <div className="d-flex justify-content-end">
        <button
          className="btn"
          data-toggle="modal"
          onClick={() => childRef.current.openModal(id)}
        >
          + Create New Sub
        </button>
      </div>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Index</th>
            <th>Method</th>
            <th>Title</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {activities?.map((item, key) => (
            <tr key={'activity' + key}>
              <th>{item.index}</th>
              <td className="align-top">
                <Badge bg="primary">{methodList[item.method]}</Badge>
              </td>
              <th>{item.sub_name}</th>
              <td className="align-top">
                <div className="d-flex justify-content-center gap-2">
                  <button title="Edit" className="btn btn-primary shadow btn sharp" onClick={() => childRef.current.openEdit(item)}>
                    <i className="fas fa-pencil-alt color-muted"></i>
                  </button>
                  <button
                    onClick={(e) => destroyActivity(e, item.id)}
                    title="delete"
                    className="btn btn-danger shadow btn sharp"
                  >
                    <i className="fa fa-close color-danger"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <BasicModal id={id} fetchActivities={fetchActivities} ref={childRef} refer={refer} />
    </>
  );
};
export default SubLearningPath;
