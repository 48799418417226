import React, { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import "react-modal-video/scss/modal-video.scss";
import course7 from "./../../../../images/course/7.jpg";
import CkEditorBlog from "./CkEditorBlog";
import { Link, useLocation } from "react-router-dom";
import Meet from "./Meet";
import { socket } from "../../../../services/SocketService";
import { useDispatch } from "react-redux";
import { createPostAction, getPostsAction } from "../../../../store/actions/PostActions";
import Chat from "./Chat";

const practicum = {
  id: "1",
  image: course7,
  tags: ["Physics", "Bernoulli"],
  title: "Drone",
};

const progress = {
  finished: 3,
  total: 18,
  current: "Workspace",
};

function PracticumStart() {
  const user = JSON.parse(localStorage.getItem('userDetails'));
  const [timer, setTimer] = useState(1800);
  let randomColors = [
    "#FF5733", "#33FF57", "#5733FF", "#33FFF5", "#FF33F5",
    "#F5FF33", "#FF3380", "#33FF80", "#8033FF", "#33FFB8",
    "#FFB833", "#F533FF", "#33A1FF", "#A1FF33", "#FF33A1",
    "#33FF33", "#FF3333", "#33A133", "#A133FF", "#F5A133"
  ];

  const location = useLocation();
  const { moduleId, scheduleId } = location.state || {};
  const methodList = ['', 'Virtual', 'Hybrid', 'On-site'];
  const [active, setActive] = useState(0);
  const MouseRef = useRef(null);
  const [coords, setCoords] = useState([]);
  const [position, setPosition] = useState([]);
  const isClickedRef = useRef(false);
  const [divSize, setDivSize] = useState({ width: 1, height: 1, left: 1, top: 1 });
  useEffect(() => {
    if (MouseRef.current) {
      const rect = MouseRef.current.getBoundingClientRect();
      setDivSize({ width: rect.width, height: rect.height, left: rect.left, top: rect.top })
    }
  }, [MouseRef.current])
  const handleMouseMove = (event) => {
    if (MouseRef.current) {
      const rect = MouseRef.current.getBoundingClientRect();
      const x = event.clientX / rect.width;
      const y = event.clientY / rect.height;
      if (isClickedRef.current) {
        setPosition({ x, y, clicked: { x, y } });
      } else {
        setPosition({ x, y, clicked: {} });
      }
    }
  };

  const handleMouseDown = () => {
    isClickedRef.current = true;
  };

  const handleMouseUp = () => {
    isClickedRef.current = false;
  };
  const handleIframeLoad = () => {
    if (MouseRef.current && MouseRef.current.contentWindow) {
      MouseRef.current.contentWindow.document.addEventListener('mousedown', handleMouseDown);
      MouseRef.current.contentWindow.document.addEventListener('mousemove', handleMouseMove);
      MouseRef.current.contentWindow.document.addEventListener('mouseup', handleMouseUp);
    }
  }
  useEffect(() => {
    return () => {
      if (MouseRef.current && MouseRef.current.contentWindow) {
        MouseRef.current.contentWindow.document.removeEventListener('mousedown', handleMouseDown);
        MouseRef.current.contentWindow.document.removeEventListener('mousemove', handleMouseMove);
        MouseRef.current.contentWindow.document.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [MouseRef.current]);
  // useEffect(() => {
  //   const clickAtCoordinates = ({ x, y }) => {
  //     const iframe = MouseRef.current;
  //     if (iframe && iframe.contentWindow && iframe.contentDocument) {
  //       const iframeDoc = iframe.contentDocument;
  //       const iframeWindow = iframe.contentWindow;
  //       const iframeRect = iframe.getBoundingClientRect();

  //       // Calculate the coordinates relative to the iframe's viewport
  //       const iframeX = x * iframeRect.width;
  //       const iframeY = y * iframeRect.height;

  //       // Get the element at the specified coordinates inside the iframe
  //       const el = iframeDoc.elementFromPoint(iframeX, iframeY);

  //       if (el) {
  //         // Create and dispatch a click event inside the iframe
  //         const clickEvent = new iframeWindow.MouseEvent('click', {
  //           bubbles: true,
  //           cancelable: true,
  //           view: iframeWindow,
  //           clientX: iframeX,
  //           clientY: iframeY,
  //         });
  //         el.dispatchEvent(clickEvent);
  //       } else {
  //         console.log('No element found at the specified coordinates inside iframe.');
  //       }
  //     } else {
  //       console.log('Iframe is not accessible.');
  //     }
  //   };

  //   if (Object.keys(coords.clicked).length !== 0) {
  //     clickAtCoordinates(coords.clicked);
  //   }
  // }, [coords.clicked]);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        }
        return prevTimer;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const dispatch = useDispatch();
  const [chatOpen, setChatOpen] = useState(false);
  const [learningMaterials, setLearningMaterials] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [subModuleIds, setSubModuleIds] = useState([]);
  const openChat = () => {
    setChatOpen(!chatOpen);
  }
  const fetchQuestions = async (subId) => {
    try {
      const response = await dispatch(getPostsAction('/question/index', {
        params: {
          subModuleId: subId,
          practicumId: moduleId
        },
      }));
      if (response) {
        setQuestions(response)
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }

  const fetchData = async () => {
    try {
      const response = await dispatch(
        getPostsAction('/group/get-group', {
          params: {
            scheduleId: scheduleId,
          },
        })
      );
      if (response) {
        const response2 = await dispatch(
          getPostsAction('/learning-path/step')
        );

        if (response2) {
          setLearningMaterials(response2);
          setActive(response2[0].sub_modules[0]?.id);
          console.log(response.virtual.includes(response2[0].sub_modules[0]?.id));

          setSubModuleIds(
            response2.flatMap((item) => item.sub_modules.map((sub) => sub.id))
          );
        }
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };
  useEffect(() => {
    socket.emit('join_room', { username: user.username, room: scheduleId });
    fetchData();
  }, []);
  useEffect(() => {
    fetchQuestions(active)
  }, [active])

  const handleNext = async () => {
    if (subModuleIds.indexOf(active) < subModuleIds.length - 1) {
      setActive(subModuleIds[subModuleIds.indexOf(active) + 1])
    }
  };
  const handlePrevious = async () => {
    if (subModuleIds.indexOf(active) > 0) {
      setActive(subModuleIds[subModuleIds.indexOf(active) - 1])
    }
  };

  const [answer, setAnswer] = useState("<p></p>");
  const submit = async (e, questionId) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("text", answer);
    formData.append("questionId", questionId);
    formData.append("practicumId", moduleId);
    // const response = dispatch(createPostAction("/answer/store", formData));
    console.log(...formData);
  };

  return (
    <>
      <div className="row ">
        <div className="col-xl-3 col-xxl-3">
          <div className="card h-auto">
            <div className="card-body">
              <div>
                <div className="card-body pt-0 bg-white" >
                  <div className="progress-box">
                    <ProgressBar now={(progress.finished / progress.total) * 100} />
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-0 fs-14 font-w600">{progress.current}</h5>
                      <span className="font-w600">
                        {progress.finished}/{progress.total}
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="m-0 w-100" />
                <PerfectScrollbar style={{ height: "78vh" }}>
                  {learningMaterials.map((item, key) => (
                    <div key={key} className="about-content">
                      <h3 className="heading mb-3">{item.name}</h3>
                      <div
                        style={{ height: "auto" }}
                        id="DZ_W_TimeLine"
                        className="widget-timeline dlab-scroll height370 ps ps--active-y"
                      >
                        <ul className="timeline">
                          {item.sub_modules.map(({ id, sub_name, method, status = 0 }, index) => {
                            const badge = id === active ? "timeline-badge primary" : "timeline-badge dark";
                            return (
                              <li key={'subModules' + id} className="cursor-pointer" onClick={() => { setActive(id) }}>
                                <div className={badge}></div>
                                <div className="timeline-panel text-muted">
                                  <span>{methodList[method]}</span>
                                  <h6 className="mb-0">{sub_name}</h6>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ))}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-xxl-6">
          <div className="card h-auto">
            <div className="card-body">
              <PerfectScrollbar style={{ height: "79vh" }}>
                {questions.map((item, index) => {
                  return (
                    false ?
                      <div
                        className="h-100 w-100 d-flex justify-content-center"
                      >
                        {
                          Object.entries(coords).map(([student, item], index) => (
                            <span className="position-absolute d-flex flex-column fw-bold fs-4" key={'coord' + student} style={{ left: `${(item.x * divSize.width) + 25}px`, top: `${(item.y * divSize.height) + 25}px` }}>
                              <span className="p2 px-3 rounded text-white" style={{ backgroundColor: randomColors[index] }}>{student}</span>
                              <i className={`fa-solid fa-arrow-pointer`} style={{ color: randomColors[index] }}></i>
                            </span>

                          ))
                        }
                        <iframe
                          ref={MouseRef}
                          onLoad={handleIframeLoad}
                          className="w-100 h-100"
                          src={'https://hiprolab.com/bridge.html'}
                        />
                      </div> :
                      <div key={'question' + index}>
                        <div className="course-content d-flex justify-content-end flex-wrap">
                          <span className="text-muted">{item.type == 1 ? 'Description' : 'Question'}</span>
                        </div>
                        <div className="course-details-tab style-2 mt-4">
                          <div className="about-content" dangerouslySetInnerHTML={{ __html: item.question }} />
                        </div>
                        {item.type === 2 && (
                          <>
                            <div className="style-2 mt-4">
                              <h6>Insert your answer below:</h6>
                            </div>
                            <div className="form-group mb-4 col-12">
                              <CkEditorBlog data={answer} setData={setAnswer} />
                            </div>
                            <button className="btn btn-sm btn-primary mb-4" onClick={(e) => submit(e, item.id)}>
                              Save this answer
                            </button>
                          </>
                        )}
                      </div>)
                })}
              </PerfectScrollbar>
            </div>
          </div>
          <div className="d-flex justify-content-between gap-2">
            <button className="btn btn-warning btn-block" onClick={handlePrevious} disabled={subModuleIds.indexOf(active) <= 0}>
              Previous
            </button>
            <button className="btn btn-primary btn-block" onClick={handleNext} disabled={subModuleIds.indexOf(active) >= subModuleIds.length - 1}>
              Next
            </button>
          </div>
        </div>
        <div className={`h-100 position-fixed d-flex ${chatOpen ? 'col-xl-5 col-xxl-5 justify-content-end ' : 'col-xl-2 col-xxl-3 col-md-3'} `} style={{ right: '0px' }}>
          <Chat socket={socket} room={scheduleId} user={user} chatOpen={chatOpen} />
          <div className={chatOpen ? 'col-xl-5 col-xxl-3 col-md-3' : 'w-100'}>
            <div className="bg-white h-auto rounded-0 rounded-top rounded-4">
              <div className="text-center border-0 justify-content-center">
                <h3 className="m-0">
                  {Math.floor(timer / 60).toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
                    ":" +
                    (timer % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 })}
                </h3>
              </div>
            </div>
            <div className="bg-dark" style={{ height: '88%' }}>

              <Meet socket={socket} user={user} setCoords={setCoords} position={position} openChat={openChat} />
            </div>
            <Link
              to={`/student/dashboard`}
              className="btn btn-danger btn-block mb-4 rounded-0 rounded-bottom rounded-4"
            >
              Exit
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default PracticumStart;
