import React, { useState, useReducer, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Modal } from "react-bootstrap";
import MOCK_DATA from "./MOCK_DATA_USER.json";
import avartar1 from "../../../images/avatar/1.png";
import { useDispatch, useSelector } from "react-redux";
import { deletePostAction, getPostsAction } from "../../../store/actions/PostActions";

const init = false;
const reducer = (state, action) => {
  if (action.type === "reviewModal") {
    return { ...state, reviewModal: !state.reviewModal };
  }
  return state;
};

export const UserManagement = () => {
  const [statee, dispatch] = useReducer(reducer, init);
  const [searchBut, setSearchBut] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10); // Set the number of items per page
  const handleEdit = (id) => console.log("Edit row with id:", id);
  const handleDelete = (id) => console.log("Delete row with id:", id);
  const pageCount = Math.ceil(MOCK_DATA.length / itemsPerPage);
  const dispatch2 = useDispatch();
  const posts = useSelector(state => state.posts)
  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    try {
      await dispatch2(getPostsAction('/user/index', {
        params: {
          pageSize: 10,
          pageNumber: 1,
        },
      }));
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };
  useEffect(() => {
      setPageData(posts.posts);
  }, [posts.posts]);
  useEffect(() => {
    setPageData([]);
    fetchData();
  }, []);
  const destroy = (e, id) => {
    e.preventDefault();
    dispatch2(deletePostAction('/test/delete/', id))
  }
  return (
    <>
      <PageTitle activeMenu="Filtering" motherMenu="Table" />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">User Management</h4>
          <div className="header-left">
            <div className="input-group search-area">
              <input
                type="text"
                className={`form-control ${searchBut ? "active" : ""}`}
                placeholder="Search here..."
              />
              <span className="input-group-text">
                <Link to={"#"}>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                      fill="#01A3FF"
                    />
                  </svg>
                </Link>
              </span>
            </div>
          </div>
          <Link
            className="btn"
            data-toggle="modal"
            onClick={() => dispatch({ type: "reviewModal" })}
            data-target="#reviewModal"
          >
            + Create New User
          </Link>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table dataTable display table-striped">
              <thead>
                <tr>
                  <th>No</th>
                  <th>NIM/NIDN</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Prodi</th>
                  <th>Universitas</th>
                  <th>Roles</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pageData.map((row, index) => (
                  <tr key={row.id}>
                    <td>{currentPage * itemsPerPage + index + 1}</td>
                    <td>
                      <div className="media d-flex align-items-center">
                        <div className="avatar avatar-xl me-2">
                          <div className="">
                            <img
                              className="rounded-circle img-fluid"
                              src={avartar1}
                              width="30"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="mb-0 fs--1">{row.username}</h5>
                        </div>
                      </div>
                    </td>
                    <td>{row.first_name}</td>
                    <td>{row.last_name}</td>
                    <td>{row.email}</td>
                    <td>{row.prodi}</td>
                    <td>{row.universitas}</td>
                    <td>{row.roles}</td>
                    <td className="text-end">
                      <span className="d-flex justify-content-end">
                        <Link
                          to="/admin/user-management"
                          className="me-2 btn btn-primary shadow btn-xs sharp"
                          onClick={() => handleEdit(row.id)}
                          title="Edit"
                        >
                          <i className="fas fa-pencil-alt color-muted"></i>{" "}
                        </Link>
                        <Link
                          to="/admin/user-management"
                          title="Close"
                          className="btn btn-danger shadow btn-xs sharp"
                          onClick={() => handleDelete(row.id)}
                        >
                          <i className="fa fa-close color-danger"></i>
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-between mt-3">
              <span>
                Page{" "}
                <strong>
                  {currentPage + 1} of {pageCount}
                </strong>
              </span>
              <span className="table-index">
                Go to page:{" "}
                <input
                  type="number"
                  className="ml-2"
                  value={currentPage + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    if (pageNumber >= 0 && pageNumber < pageCount) {
                      setCurrentPage(pageNumber);
                    }
                  }}
                />
              </span>
            </div>
            <div className="text-center mb-3">
              <div className="filter-pagination mt-3">
                <button
                  className="previous-button"
                  onClick={() => setCurrentPage(0)}
                  disabled={currentPage === 0}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 0))
                  }
                  disabled={currentPage === 0}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, pageCount - 1))
                  }
                  disabled={currentPage === pageCount - 1}
                >
                  Next
                </button>
                <button
                  className="next-button"
                  onClick={() => setCurrentPage(pageCount - 1)}
                  disabled={currentPage === pageCount - 1}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Create New Practicum */}
      <Modal
        show={statee.reviewModal}
        onHide={() => dispatch({ type: "reviewModal" })}
        className="modal fade"
        id="reviewModal"
      >
        <>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create New User</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => dispatch({ type: "reviewModal" })}
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  dispatch({ type: "reviewModal" });
                }}
              >
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="First Name*"
                    defaultValue={""}
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Last Name*"
                    defaultValue={""}
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="NIM/NIDN*"
                    defaultValue={""}
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Email*"
                    defaultValue={""}
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Password*"
                    defaultValue={""}
                  />
                </div>
                <button className="btn btn-success btn-block">Save</button>
              </form>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default UserManagement;
