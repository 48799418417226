import {
    CONFIRMED_CREATE_POST_ACTION,
    CONFIRMED_DELETE_POST_ACTION,
    CONFIRMED_EDIT_POST_ACTION,
    CONFIRMED_GET_POSTS,
    CREATE_POST_ACTION,
    RESET_POSTS,
} from '../actions/PostTypes';

const initialState = {
    posts: [],
    successMessage: null,
};

export default function PostsReducer(state = initialState, actions) {
    switch (actions.type) {
        case CREATE_POST_ACTION: {
            const post = {
                id: Math.random(),
                title: 'Post Title 2asdasd',
                description: 'Sample Description 2asdasdas',
            };

            const posts = [...state.posts];
            posts.push(post);
            return {
                ...state,
                posts,
            };
        }
        case CONFIRMED_DELETE_POST_ACTION: {
            const posts = Array.isArray(state.posts) ? [...state.posts] : [];
            const postIndex = posts.findIndex(
                (post) => post.id === actions.payload,
            );

            if (postIndex !== -1) {
                posts.splice(postIndex, 1);
            }

            return {
                ...state,
                posts,
            };
        }
        case CONFIRMED_EDIT_POST_ACTION:
            const posts = [...state.posts];
            const postIndex = posts.findIndex(
                (post) => post.id === actions.payload.id,
            );

            posts[postIndex] = actions.payload;
            return {
                ...state,
                posts,
            };
        case CONFIRMED_CREATE_POST_ACTION: {
            const posts = Array.isArray(state.posts) ? [...state.posts] : [];
            posts.push(actions.payload);

            return {
                ...state,
                posts,
            };
        }
        case CONFIRMED_GET_POSTS:
            return {
                ...state,
                posts: actions.payload,
            };
        case RESET_POSTS:
            return {
                ...state,
                posts: []
            };
        default:
            return state;
    }
}
