import React from "react";
import { Badge } from "react-bootstrap";

const getInitials = (name) => {
  const words = name.split(" ");
  const initials = words.length > 1 
    ? words[0][0] + words[words.length - 1][0] 
    : words[0][0];
  return initials.toUpperCase();
};

const Avatar = ({ name, style }) => {
  const initials = getInitials(name);

  return (
    <Badge
      style={{
        backgroundColor: "#007bff",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "20px",
        color: "#fff",
        ...style,
      }}
    >
      {initials}
    </Badge>
  );
};

export default Avatar;
