import React, { useCallback, useEffect, useRef, useState } from "react";
import Peer from "peerjs";
import { Modal } from "react-bootstrap";

function RemotePeerVideo({ peer }) {
    useEffect(() => {
        const handleLoadedMetadata = () => {
            if (peer.stream) {
                const videoTracks = peer.stream.getVideoTracks();
                const isCameraOn = videoTracks.some((track) => track.enabled);

                if (!isCameraOn) {
                    console.log(`Peer ${peer.id} has the camera off.`);
                } else {
                    console.log(`Peer ${peer.id} has the camera On.`);
                }
            }
        };
        if (peer.videoRef.current && peer.stream) {
            peer.videoRef.current.srcObject = peer.stream;

            peer.videoRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);

            if (peer.stream.active) {
                handleLoadedMetadata();
            }
        }

        return () => {
            if (peer.videoRef.current) {
                peer.videoRef.current.removeEventListener(
                    "loadedmetadata",
                    handleLoadedMetadata
                );
            }
        };
    }, [peer.videoRef, peer.stream]);

    return (
        <div className="d-flex" style={{ position: "relative", height: "173px" }} key={peer.id}>
            <video
                id={peer.id}
                ref={peer.videoRef}
                playsInline
                autoPlay
                className="border-top border-2 border-dark w-100"
                height={173}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <div
                className="text-center"
                style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "100%",
                    zIndex: 2,
                }}
            >
                <span className="bg-dark px-3 rounded-pill text-white opacity-75 w-auto">
                    {peer.id}
                </span>
            </div>
        </div>
    );
}


export default function Meet({ socket, user, setCoords, position, room, openChat, camera = false }) {
    const webcamRef = useRef(null);
    const [peer, setPeer] = useState(null);
    const [video, setVideo] = useState(false);
    const [audio, setAudio] = useState(true);
    const [hide, setHide] = useState(false);
    const [localStream, setLocalStream] = useState(null);
    const [remotePeers, setRemotePeers] = useState({});
    const [modalCam, setModalCam] = useState(camera ? false : true);
    const callsRef = useRef({});
    const constraints = {
        video: {
            width: { ideal: 640 },  // Lower resolution
            height: { ideal: 480 },
            frameRate: { ideal: 15 }  // Lower frame rate
        },
        audio: true
    };
    useEffect(() => {
        toggleVideo();
    }, []);

    const setWebcamRef = useCallback(
        (videoElement) => {
            if (videoElement) {
                videoElement.srcObject = localStream;
            }
            webcamRef.current = videoElement;
        },
        [localStream]
    );

    useEffect(() => {
        if (!modalCam && localStream) {
            const newPeer = new Peer(user.username, {
                path: "/peerjs",
                port: "443",
                host: "api.hiprolab.com",
                secure: true,
                config: {
                    iceServers: [
                        { urls: 'stun:stun01.sipphone.com' },
                        { urls: 'stun:stun.ekiga.net' },
                        { urls: 'stun:stun.fwdnet.net' },
                        { urls: 'stun:stun.ideasip.com' },
                        { urls: 'stun:stun.iptel.org' },
                        { urls: 'stun:stun.rixtelecom.se' },
                        { urls: 'stun:stun.schlund.de' },
                        { urls: 'stun:stun.l.google.com:19302' },
                        { urls: 'stun:stun1.l.google.com:19302' },
                        { urls: 'stun:stun2.l.google.com:19302' },
                        { urls: 'stun:stun3.l.google.com:19302' },
                        { urls: 'stun:stun4.l.google.com:19302' },
                        { urls: 'stun:stunserver.org' },
                        { urls: 'stun:stun.softjoys.com' },
                        { urls: 'stun:stun.voiparound.com' },
                        { urls: 'stun:stun.voipbuster.com' },
                        { urls: 'stun:stun.voipstunt.com' },
                        { urls: 'stun:stun.voxgratia.org' },
                        { urls: 'stun:stun.xten.com' },
                        {
                            urls: "stun:103.132.124.200:5350",
                        },
                        {
                            urls: "turn:103.132.124.200:3479",
                            username: "hiprolab",
                            credential: "testHiprolab2024",
                        },
                    ]
                },
            });

            newPeer.on("open", (id) => {
                socket.emit("peer-users", id);
            });

            newPeer.on("call", (call) => {
                if (call.peer !== user.username) {
                    call.answer(localStream);
                    callsRef.current[call.peer] = call;
                    call.on("stream", (incomingStream) => {
                        setRemotePeers((prevRemotePeers) => {
                            if (call.peer in prevRemotePeers) {
                                return {
                                    ...prevRemotePeers,
                                    [call.peer]: {
                                        ...prevRemotePeers[call.peer],
                                        stream: incomingStream,
                                    },
                                };
                            } else {
                                const videoRef = React.createRef();
                                return {
                                    ...prevRemotePeers,
                                    [call.peer]: { id: call.peer, videoRef, stream: incomingStream },
                                };
                            }
                        });
                    });


                    call.on("close", () => {
                        setRemotePeers((prevRemotePeers) => {
                            const updatedRemotePeers = { ...prevRemotePeers };
                            delete updatedRemotePeers[call.peer];
                            return updatedRemotePeers;
                        });
                        delete callsRef.current[call.peer];
                    });
                }
            });

            setPeer(newPeer);

            return () => {
                newPeer.disconnect();
                newPeer.destroy();
            };
        }
    }, [modalCam, localStream]);

    useEffect(() => {
        if (!modalCam && peer && localStream) {
            socket.on("existing-peers", (existingPeers) => {
                existingPeers.forEach((peerId) => {
                    if (peerId !== user.username) {
                        connectToNewPeer(peerId);
                    }
                });
            });

            socket.on("new-peer", (peerId) => {
                if (peerId !== user.username) {
                    connectToNewPeer(peerId);
                }
            });

            socket.on("peer-disconnected", (peerId) => {
                if (callsRef.current[peerId]) {
                    callsRef.current[peerId].close();
                    delete callsRef.current[peerId];
                }
                setRemotePeers((prevRemotePeers) => {
                    const updatedRemotePeers = { ...prevRemotePeers };
                    delete updatedRemotePeers[peerId];
                    return updatedRemotePeers;
                });
            });

            return () => {
                socket.off("existing-peers");
                socket.off("new-peer");
                socket.off("peer-disconnected");
            };
        }
    }, [peer, modalCam, localStream]);
    const reconnectToPeer = (peerId) => {
        if (callsRef.current[peerId]) {
            callsRef.current[peerId].close();
            delete callsRef.current[peerId];
        }
        console.log(`Reconnecting to peer ${peerId}...`);
        connectToNewPeer(peerId);
    };
    const monitorIceConnection = (peerConnection, peerId) => {
        if (peerConnection) {
            peerConnection.addEventListener("iceconnectionstatechange", () => {
                const state = peerConnection.iceConnectionState;
                console.log(`ICE connection state with peer ${peerId}:`, state);

                if (state === "disconnected" || state === "failed") {
                    console.warn(`Peer ${peerId} disconnected or failed.`);
                    setTimeout(() => reconnectToPeer(peerId), 3000);
                } else if (state === "connected") {
                    console.log(`Peer ${peerId} connected successfully.`);
                }
            });
        }
    };
    const connectToNewPeer = (peerId) => {
        if (!localStream) {
            console.warn("Local stream not available yet.");
            return;
        }

        const conn = peer.connect(peerId);
        conn.on("open", () => {
            console.log("Connected to ", peerId);
        });
        conn.on("data", (data) => {
            setCoords((prevItems) => ({
                ...prevItems,
                [peerId]: data,
            }));
        });
        const call = peer.call(peerId, localStream);
        callsRef.current[peerId] = call;
        call.on("stream", (incomingStream) => {
            const peerConnection = call.peerConnection;
            monitorIceConnection(peerConnection, peerId);
        });

        call.on("error", (err) => {
            console.error("Call error:", err);
        });
    };

    const toggleVideo = async () => {
        const newVideoState = !video;
        setVideo(newVideoState);

        try {
            if (!localStream) {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                setLocalStream(stream);
                if (webcamRef.current) {
                    webcamRef.current.srcObject = stream;
                }
            } else {
                localStream.getVideoTracks().forEach((track) => {
                    track.enabled = newVideoState;
                });
            }
        } catch (error) {
            console.error("Error accessing media devices.", error);
        }
    };

    const toggleAudio = () => {
        const newAudioState = !audio;
        setAudio(newAudioState);

        if (localStream) {
            localStream.getAudioTracks().forEach((track) => {
                track.enabled = newAudioState;
            });
        }
    };

    const toggleHide = () => {
        setHide((prevHide) => !prevHide);
    };

    return (
        <div>
            {camera ? (
                <video
                    ref={setWebcamRef}
                    autoPlay
                    playsInline
                    muted
                    className="border-top border-2 border-dark"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
            ) : (
                <>
                    <Modal onHide={() => setModalCam(false)} show={modalCam} size="lg" centered>
                        <div
                            className={`px-4 rounded-top text-center d-flex gap-2 `}
                            style={{ backgroundColor: "#272C36", marginTop: "-5px" }}
                        >
                            <div className="text-center pt-2 w-100">
                                <button className="btn p-2 text-danger btn-icon" onClick={toggleAudio}>
                                    {audio ? (
                                        <i className="fa-solid fa-microphone fa-lg"></i>
                                    ) : (
                                        <i className="fa-solid fa-microphone-slash fa-lg"></i>
                                    )}
                                </button>
                                <button className="btn p-2 text-info btn-icon" onClick={toggleVideo}>
                                    {video ? (
                                        <i className="fa-solid fa-video fa-lg"></i>
                                    ) : (
                                        <i className="fa-solid fa-video-slash fa-lg"></i>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div
                            className={`${hide ? "d-none" : "d-flex"
                                } flex-column text-white rounded-3 rounded-bottom`}
                            style={{ backgroundColor: "#1f232b", position: "relative" }}
                        >
                            <div className="d-flex" style={{ position: "relative", height: "273px" }}>
                                {video && modalCam ? (
                                    <video
                                        ref={setWebcamRef}
                                        autoPlay
                                        playsInline
                                        className="border-top border-2 border-dark"
                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                    />
                                ) : (
                                    !hide && <i className="fa-solid fa-video-slash fa-lg m-auto"></i>
                                )}
                                <div
                                    className="text-center"
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        width: "100%",
                                        zIndex: 2,
                                    }}
                                ></div>
                            </div>
                        </div>
                        <button className="btn btn-primary" onClick={() => setModalCam(false)}>
                            Join
                        </button>
                    </Modal>
                    <div className="shadow round-top round-bottom">
                        <div
                            className={`px-4 text-center d-flex gap-2 round-top ${hide ? "round-bottom" : ""
                                }`}
                            style={{ backgroundColor: "#272C36", marginTop: "-5px" }}
                        >
                            <div className="text-center pt-2 w-100">
                                <button className="btn p-2 text-success btn-icon" onClick={() => openChat()}>
                                    <i className="fa-solid fa-comments"></i>
                                </button>
                                <button className="btn p-2 text-danger btn-icon" onClick={toggleAudio}>
                                    {audio ? (
                                        <i className="fa-solid fa-microphone fa-lg"></i>
                                    ) : (
                                        <i className="fa-solid fa-microphone-slash fa-lg"></i>
                                    )}
                                </button>
                                <button className="btn p-2 text-info btn-icon" onClick={toggleVideo}>
                                    {video ? (
                                        <i className="fa-solid fa-video fa-lg"></i>
                                    ) : (
                                        <i className="fa-solid fa-video-slash fa-lg"></i>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div
                            className={`${hide ? "d-none" : "d-flex"} flex-column text-white round-bottom`}
                            style={{ backgroundColor: "#1f232b", position: "relative" }}
                        >
                            {/* Local Video */}
                            <div className="d-flex" style={{ position: "relative", height: "173px" }}>
                                {video && !modalCam ? (
                                    <video
                                        ref={setWebcamRef}
                                        autoPlay
                                        playsInline
                                        muted
                                        className="border-top border-2 border-dark"
                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                    />
                                ) : (
                                    !hide && <i className="fa-solid fa-video-slash fa-lg m-auto"></i>
                                )}
                                <div
                                    className="text-center"
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        width: "100%",
                                        zIndex: 2,
                                    }}
                                >
                                    <span className="bg-dark px-3 rounded-pill text-white opacity-75 w-auto">
                                        {user.displayName}
                                    </span>
                                </div>
                            </div>

                            {/* Remote Videos */}
                            {Object.values(remotePeers).map((peer) => (
                                <RemotePeerVideo key={peer.id} peer={peer} />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
